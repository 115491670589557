import React, { useState } from "react";
import aboutImg from "../../../../assets/lavioleta/Aboutimg.png";
import { useNavigate } from "react-router-dom";
import PopupModel from "../componets/Lmodel";

export const LaboutSection = () => {
  const navigate = useNavigate();
  const [ShowPopup, setShowPopup] = useState(false);

  //navigation
  const goToAboutPage = () => {
    navigate("/about");
  };
  return (
    <div
      id="about"
      className="about-section w-full bg-[#ffffff] flex items-center justify-center"
    >
      <div className="container max-w-[1240px] py-6  px-4">
        <h1 className="text-[#000000] m-auto w-fit mb-4 mt-3">
          A Place Where you belong
        </h1>
        <div className="  md:py-9 grid  md:grid-cols-2 relative">
          <img
            src={aboutImg}
            alt="about image"
            className="w-[80%] order-last md:order-first md:w-[80%] m-auto"
            loading="lazy"
          />
          <div>
            <p className="text-justify">
              Villanova welcomes you home with lush landscapes as well as
              impressive amenities and facilities that bind the community
              together. Green parks, running tracks and walkways, playgrounds,
              pools, a community centre and the convenience of life are all
              present in a perfectly crafted neighbourhood. In addition, the
              connectivity from major highways allows residents to enjoy the
              city and surrounding areas with ease.
            </p>
            <p className="text-justify">
              Introducing the final phase of La Violeta, a serene addition to
              Villanova, featuring 3 and 4-bedroom townhouses. The spacious
              homes are thoughtfully designed with straight lines that create a
              modern look while integrating the architecture with the verdant
              surroundings. The latest release at one of Dubai’s most desirable
              and popular communities contains plenty of greenery and amenities
              to enjoy life beyond four walls.
            </p>
            <button
              onClick={() => setShowPopup(true)}
              className="site-btn !text-[#000000] hover:!text-[#ffffff] !border-[#000000] hover:!bg-black"
            >
              Request callback
            </button>
          </div>
        </div>
      </div>
      <div>
        {ShowPopup && <PopupModel onClose={() => setShowPopup(false)} />}
      </div>
    </div>
  );
};

export default LaboutSection;
