import React, { useEffect, useState } from "react";
import projectCover from "../../assets/icons/coverimage.webp";
import LoadImg from "../../assets/icons/loadimg.webp";
import pool from "../../assets/icons/swimming_pool.webp";
import health from "../../assets/icons/rehabilitation.webp";
import Retailoutlet from "../../assets/icons/wholesaler.webp";
import gym from "../../assets/icons/weights.webp";
import park from "../../assets/icons/park.webp";
import restro from "../../assets/icons/restaurant.webp";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import Profile from "../../assets/icons/leader_sale.webp";
import { IoMdHelpCircle } from "react-icons/io";
import { IoChevronForwardCircle } from "react-icons/io5";
import { useProjectServices } from "../../services/projectServices";
import { PopupModel } from "../model/PopupModel";
import { FloorPlan } from "./componets/FloorPlan";
import { URL } from "../../url/axios";
import ProjectConnect from "../pages/projectDetail/components/ProjectConnect";
import PaymentPlan from "./componets/PaymentPlan";

export const CampainPage = (url, title,) => {
  // Hardcoded project ID
  const projectId = "66d1d4b06e253e1b7cb04aee"; // Replace with the desired ID
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [navR, setNavR] = useState(true);
  const { getProjectPublicList } = useProjectServices();

  useEffect(() => {
    fetchProject();

      const handleScroll = () => {
    const stickyDiv = document.getElementById("stickyDiv");
    const content = document.getElementById("content");

    // Check if content exists before accessing its properties
    if (content) {
      const contentHeight = content.offsetHeight;
      const scrollPosition = window.scrollY;

      // Calculate when to make the sticky div visible
      if (scrollPosition > contentHeight / 2) {
        stickyDiv.classList.add("sticky-active");
      } else {
        stickyDiv.classList.remove("sticky-active");
      }
    }
  };

  window.addEventListener("scroll", handleScroll);

  // Cleanup the event listener on component unmount
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
    

  }, []);

  const fetchProject = async () => {
    try {
      const response = await getProjectPublicList(); // No ID parameter needed
      console.log("API Response:", response); // Log the entire response

      if (response.success) {
        const projects = response.data;
        console.log("Matching Team Data:", projects);

        if (Array.isArray(projects)) {
          // Find the specific project based on the hardcoded ID
          const project = projects.find((proj) => proj._id === projectId);
          console.log("Found Project:", project); // Log the found project

          if (project) {
            setProjectData(project);
          } else {
            setError("No project found with the specified ID.");
          }
        } else {
          setError("Unexpected data format.");
        }
      } else {
        setError("Failed to fetch project details.");
      }
    } catch (err) {
      console.error("Failed to fetch project details", err);
      setError("An error occurred while fetching project details.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-[#040406] text-center">
        <p className="m-auto loader !w-[24px] !h-[24px]"></p>
      </div>
    ); // Loading indicator
  }

  if (error) {
    return <div>{error}</div>; // Display error message
  }

  //nav menu button
  const handleNavR = () => {
    setNavR(!navR);
  };

  const currentUrl = window.location.href;

  const shareOnFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        currentUrl
      )}`,
      "_blank"
    );
  };

  const shareOnInstagram = () => {
    alert(
      "Instagram does not support direct link sharing. Please copy the link and share it manually in your Instagram app."
    );
  };

  const shareOnLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        currentUrl
      )}&title=${encodeURIComponent(title)}`,
      "_blank"
    );
  };

  const shareOnWhatsApp = () => {
    window.open(
      `https://wa.me/?text=${encodeURIComponent(title + " " + currentUrl)}`,
      "_blank"
    );
  };




  return (
    <div>
      <div
        className="banner w-full bg-[#040406] flex items-center justify-center"
        style={{
          backgroundImage: `url(${
            projectData?.coverimage
              ? URL + projectData.coverimage
              : projectCover
          })`,
        }}
      >
        <div className="container max-w-[1240px] px-4 flex items-center justify-start z-10">
          <div className="banner-content">
            <div className="grid md:grid-cols-2">
              <div>
                <h1 className="banner-h1">{projectData?.bannertitile}</h1>
                <p className="pb-4">{projectData?.bannersubtitile}</p>
                <button onClick={() => setShowPopup(true)} className="site-btn">
                  Request callback
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#00000066] w-full h-full absolute left-0 top-0 z-0 sm:hidden"></div>
        <div>
          {showPopup && <PopupModel onClose={() => setShowPopup(false)} />}
        </div>
      </div>

      <div className=" w-full bg-[#040406] flex items-center justify-center">
        <div className="container max-w-[1240px] py-5  px-4  md:py-9 grid  lg:grid-cols-3 relative">
          <div className="col-span-2">
            <h1>{projectData?.mainhead}</h1>
            <p>Personally Visited & Approved</p>
            <div className="grid grid-cols-3 mb-3">
              <div
                className="col-span-2 relative mr-1 h-[170px] md:h-[285px] w-full"
                style={{
                  backgroundImage: `url(${
                    projectData?.gallary1
                      ? URL + encodeURIComponent(projectData.gallary1)
                      : LoadImg
                  })`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>

              <div className="ml-1">
                <div
                  className=""
                  style={{
                    backgroundImage: `url(${
                      projectData?.gallary2
                        ? URL + encodeURIComponent(projectData.gallary2)
                        : LoadImg
                    })`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "49.5%",
                  }}
                ></div>
                <div className="h-[1.5%] w-full"></div>
                <div
                  className=""
                  style={{
                    backgroundImage: `url(${
                      projectData?.gallary2
                        ? URL + encodeURIComponent(projectData.gallary3)
                        : LoadImg
                    })`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    width: "100%",
                    height: "49.5%",
                  }}
                >
                  {/* <div className="white-cover flex">
                  <p className="m-auto text-[#000000] ">View More</p>
                </div> */}
                </div>
              </div>
            </div>
            <h6 className="text-[#ffffff] text-left text-[1rem] sm:text-[1.4rem] font-semibold mb-4">
              Features & amenities
            </h6>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-3 mb-4">
              <div className="text-[#ffffff] w-full flex items-center justify-center gap-2 rounded-md border border-[#ffffff] p-2">
                <img className="w-[1.5rem] md:w-[1.8rem]" src={park}></img>
                <p className="text-[#ffffff] m-0">Kids Play Area</p>
              </div>
              <div className="text-[#ffffff] w-full mr-1 flex items-center justify-center gap-2 rounded-md border border-[#ffffff] p-2">
                <img className="w-[1.5rem] md:w-[1.8rem]" src={pool}></img>
                <p className="text-[#ffffff] m-0">Swimming pool</p>
              </div>
              <div className="text-[#ffffff] w-full mr-1 flex items-center justify-center gap-2 rounded-md border border-[#ffffff] p-2">
                <img className="w-[1.5rem] md:w-[1.8rem]" src={health}></img>
                <p className="text-[#ffffff] m-0">Health Care Centre</p>
              </div>
              <div className="text-[#ffffff] w-full mr-1 flex items-center justify-center gap-2 rounded-md border border-[#ffffff] p-2">
                <img className="w-[1.5rem] md:w-[1.8rem]" src={gym}></img>
                <p className="text-[#ffffff] m-0">Gymnasium</p>
              </div>
              <div className="text-[#ffffff] w-full mr-1 flex items-center justify-center gap-2 rounded-md border border-[#ffffff] p-2">
                <img
                  className="w-[1.5rem] md:w-[1.8rem]"
                  src={Retailoutlet}
                ></img>
                <p className="text-[#ffffff] m-0">Retail Outlets</p>
              </div>
              <div className="text-[#ffffff] w-full mr-1 flex items-center justify-center gap-2 rounded-md border border-[#ffffff] p-2">
                <img
                  className="w-[1.5rem] md:w-[1.8rem] backdrop-brightness-200"
                  src={restro}
                ></img>
                <p className="text-[#ffffff] m-0">Restaurants</p>
              </div>
            </div>
            <h6 className="text-[#ffffff] text-left text-[1rem] sm:text-[1.4rem] font-semibold mb-4">
              Lifestyle at {projectData?.projectname}
            </h6>
            <p>{projectData?.about}</p>
            <p>{projectData?.about1}</p>
            <p>{projectData?.about2}</p>

            {/* tab section */}
            <h6 className="text-[#ffffff] text-left text-[1rem] sm:text-[1.4rem] font-semibold mb-4">
              Floor Plan
            </h6>
            <FloorPlan />
            <h6 className="text-[#ffffff] text-left text-[1rem] sm:text-[1.4rem] font-semibold mb-4">
              Payment Plan
            </h6>
            <PaymentPlan />
            <h6 className="text-[#ffffff] text-left text-[1rem] sm:text-[1.4rem] font-semibold mb-4">
              Location
            </h6>
            <iframe class="map mb-3" src={projectData?.location}></iframe>
            <div className="grid md:grid-cols-2">
              <div>
                <h6 className="text-[#ffffff] text-left text-[1rem] sm:text-[1.4rem] font-semibold">
                  {projectData?.nearby1}
                </h6>
                <p>{projectData?.dec1}</p>
              </div>
              <div>
                <h6 className="text-[#ffffff] text-left text-[1rem] sm:text-[1.4rem] font-semibold">
                  {projectData?.nearby2}
                </h6>
                <p>{projectData?.dec2}</p>
              </div>
              <div>
                <h6 className="text-[#ffffff] text-left text-[1rem] sm:text-[1.4rem] font-semibold">
                  {projectData?.nearby3}
                </h6>
                <p>{projectData?.dec3}</p>
              </div>
              <div>
                <h6 className="text-[#ffffff] text-left text-[1rem] sm:text-[1.4rem] font-semibold">
                  {projectData?.nearby4}
                </h6>
                <p>{projectData?.dec4}</p>
              </div>
            </div>
          </div>

          {/* side section */}
          <div className="pl-4 hidden lg:block">
            <div>
              <div className="border border-[#ffffff] border-spacing-1 rounded-md p-3">
                <div className="flex text-center">
                  <img
                    className="h-[60px] w-[60px] sm:h-[95px] sm:w-[95px]"
                    src={Profile}
                    alt="profile"
                  />
                  <div className="pl-2">
                    <h6 className="text-[#ffffff] text-left text-[0.9rem] sm:text-[1rem] font-semibold">
                      Velina Dsouza
                    </h6>
                    <p className="text-[0.89rem]">Property Consultant</p>
                  </div>
                </div>
                <div className="flex items-center pt-3">
                  <a
                    href="tel:+971543049309"
                    className="site-sub-btn w-full mr-1 text-center"
                  >
                    Call
                  </a>
                  <button
                    onClick={() => setShowPopup(true)}
                    className="site-sub-btn w-full ml-1"
                  >
                    Inquiry
                  </button>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                    Or get availability via
                  </p>
                  <a
                    href={`https://wa.me/+971543049309?text=Hello,%20Share%20more%20details%20${projectData.projectname}`}
                    className="flex items-center justify-center group"
                  >
                    <FaWhatsapp className="text-[#CE8745] ml-2 group-hover:text-[#6B9B2D] text-[1rem] lg:text-[1.3rem]" />
                    <p className="mb-0 text-[#CE8745] group-hover:text-[#6B9B2D] text-[0.8rem] lg:text-[1rem]">
                      WhatsApp
                    </p>
                  </a>
                </div>
              </div>
              <div className="border border-[#ffffff] border-spacing-1 rounded-md p-3 mt-3">
                <h6 className="text-[#ffffff] text-left text-[0.9rem] sm:text-[1.1rem] font-medium border-b-[#ffffff] border-b pb-2 mb-3">
                  Quick Facts
                </h6>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Project:
                  </span>
                  {projectData.projectname}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Developer:
                  </span>
                  {projectData.developer}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Bedroom:
                  </span>
                  {projectData.bedroom}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">Type:</span>
                  {projectData.type}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Handover date:
                  </span>
                  {projectData.handover}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Total Area:
                  </span>
                  {projectData.totalarea}
                </p>
              </div>
              <div className="border border-[#ffffff] border-spacing-1 rounded-md p-3 mt-3">
                <h6 className="text-[#ffffff] text-left text-[0.9rem] sm:text-[1.1rem] font-medium border-b-[#ffffff] border-b pb-2 mb-3">
                  Share
                </h6>
                <ul className="flex items-center justify-left gap-4 p-2 md:flex">
                  <li className="group">
                    <FaFacebookF
                      onClick={shareOnFacebook}
                      className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                    />
                  </li>
                  <li className="group">
                    <FaInstagram
                      onClick={shareOnInstagram}
                      className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                    />
                  </li>
                  <li className="group">
                    <FaLinkedin
                      onClick={shareOnLinkedIn}
                      className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                    />
                  </li>
                  <li className="group">
                    <RiWhatsappFill
                      onClick={shareOnWhatsApp}
                      className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                    />
                  </li>
                </ul>
              </div>
              <div>
                {showPopup && (
                  <PopupModel onClose={() => setShowPopup(false)} />
                )}
              </div>
            </div>

            {/* scrolling side section */}
            <div id="content">
              <div id="stickyDiv" className="sticky mt-4">
                <div className="border border-[#ffffff] border-spacing-1 rounded-md p-3">
                  <div className="flex text-center">
                    <img
                      className="h-[60px] w-[60px] sm:h-[95px] sm:w-[95px]"
                      src={Profile}
                      alt="profile"
                    />
                    <div className="pl-2">
                      <h6 className="text-[#ffffff] text-left text-[0.9rem] sm:text-[1rem] font-semibold">
                        Velina Dsouza
                      </h6>
                      <p className="text-[0.89rem]">Property Consultant</p>
                    </div>
                  </div>
                  <div className="flex items-center pt-3">
                    <a
                      href="tel:+971543049309"
                      className="site-sub-btn w-full mr-1 text-center"
                    >
                      Call
                    </a>
                    <button
                      onClick={() => setShowPopup(true)}
                      className="site-sub-btn w-full ml-1"
                    >
                      Inquiry
                    </button>
                  </div>
                  <div className="flex items-center justify-center mt-2">
                    <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                      Or get availability via
                    </p>
                    <a
                      href={`https://wa.me/+971543049309?text=Hello,%20Share%20more%20details%20${projectData.projectname}`}
                      className="flex items-center justify-center group"
                    >
                      <FaWhatsapp className="text-[#CE8745] ml-2 group-hover:text-[#6B9B2D] text-[1rem] lg:text-[1.3rem]" />
                      <p className="mb-0 text-[#CE8745] group-hover:text-[#6B9B2D] text-[0.8rem] lg:text-[1rem]">
                        WhatsApp
                      </p>
                    </a>
                  </div>
                </div>
                <div className="border border-[#ffffff] border-spacing-1 rounded-md p-3 mt-3">
                  <h6 className="text-[#ffffff] text-left text-[0.9rem] sm:text-[1.1rem] font-medium border-b-[#ffffff] border-b pb-2 mb-3">
                    Quick Facts
                  </h6>
                  <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                    <span className="text-[#ffffff] font-medium pr-2">
                      Project:
                    </span>
                    {projectData.projectname}
                  </p>
                  <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                    <span className="text-[#ffffff] font-medium pr-2">
                      Developer:
                    </span>
                    {projectData.developer}
                  </p>
                  <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                    <span className="text-[#ffffff] font-medium pr-2">
                      Bedroom:
                    </span>
                    {projectData.bedroom}
                  </p>
                  <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                    <span className="text-[#ffffff] font-medium pr-2">
                      Type:
                    </span>
                    {projectData.type}
                  </p>
                  <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                    <span className="text-[#ffffff] font-medium pr-2">
                      Handover date:
                    </span>
                    {projectData.handover}
                  </p>
                  <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                    <span className="text-[#ffffff] font-medium pr-2">
                      Total Area:
                    </span>
                    {projectData.totalarea}
                  </p>
                </div>
                <div className="border border-[#ffffff] border-spacing-1 rounded-md p-3 mt-3">
                  <h6 className="text-[#ffffff] text-left text-[0.9rem] sm:text-[1.1rem] font-medium border-b-[#ffffff] border-b pb-2 mb-3">
                    Share
                  </h6>
                  <ul className="flex items-center justify-left gap-4 p-2 md:flex">
                    <li className="group">
                      <FaFacebookF
                        onClick={shareOnFacebook}
                        className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                      />
                    </li>
                    <li className="group">
                      <FaInstagram
                        onClick={shareOnInstagram}
                        className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                      />
                    </li>
                    <li className="group">
                      <FaLinkedin
                        onClick={shareOnLinkedIn}
                        className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                      />
                    </li>
                    <li className="group">
                      <RiWhatsappFill
                        onClick={shareOnWhatsApp}
                        className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                      />
                    </li>
                  </ul>
                </div>
                <div>
                  {showPopup && (
                    <PopupModel onClose={() => setShowPopup(false)} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* slidebar */}
        <div
          className="fixed right-[-17px] top-[15rem] bg-[#CE8745] rounded-bl-2xl rounded-tl-2xl  lg:hidden z-30 bounce-left"
          onClick={handleNavR}
        >
          {!navR ? (
            <IoChevronForwardCircle className="text-[#ffffff] text-[2rem]  mr-7" />
          ) : (
            <IoMdHelpCircle className="text-[#ffffff] text-[2rem] mr-7" />
          )}
        </div>

        <div
          className={
            !navR
              ? "fixed right-0 top-[50px] w-[85%] bg-[#040406] h-full ease-in-out duration-500 slide-bar"
              : "fixed right-[-100%] slide-bar top-15 h-full"
          }
        >
          <div className="p-4">
            <div
              className="absolute left-[-44px] top-[10rem] bg-[#CE8745] rounded-bl-2xl rounded-tl-2xl"
              onClick={handleNavR}
            >
              <IoChevronForwardCircle className="text-[#ffffff] text-[2rem]  mr-3" />
            </div>

            <div>
              <div className="border border-[#ffffff] border-spacing-1 rounded-md p-3">
                <div className="flex text-center">
                  <img
                    className="h-[60px] w-[60px] sm:h-[95px] sm:w-[95px]"
                    src={Profile}
                    alt="profile"
                  />
                  <div className="pl-2">
                    <h6 className="text-[#ffffff] text-left text-[0.9rem] sm:text-[1rem] font-semibold">
                      Velina Dsouza
                    </h6>
                    <p className="text-[0.89rem]">Property Consultant</p>
                  </div>
                </div>
                <div className="flex items-center pt-3">
                  <a
                    href="tel:+971543049309"
                    className="site-sub-btn w-full mr-1 text-center"
                  >
                    Call
                  </a>
                  <button
                    onClick={() => setShowPopup(true)}
                    className="site-sub-btn w-full ml-1"
                  >
                    Inquiry
                  </button>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                    Or get availability via
                  </p>
                  <a
                    href={`https://wa.me/+971543049309?text=Hello,%20Share%20more%20details%20${projectData.projectname}`}
                    className="flex items-center justify-center group"
                  >
                    <FaWhatsapp className="text-[#CE8745] ml-2 group-hover:text-[#6B9B2D] text-[1rem] lg:text-[1.3rem]" />
                    <p className="mb-0 text-[#CE8745] group-hover:text-[#6B9B2D] text-[0.8rem] lg:text-[1rem]">
                      WhatsApp
                    </p>
                  </a>
                </div>
              </div>
              <div className="border border-[#ffffff] border-spacing-1 rounded-md p-3 mt-3">
                <h6 className="text-[#ffffff] text-left text-[0.9rem] sm:text-[1.1rem] font-medium border-b-[#ffffff] border-b pb-2 mb-3">
                  Quick Facts
                </h6>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Project:
                  </span>
                  {projectData.projectname}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Developer:
                  </span>
                  {projectData.developer}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Bedroom:
                  </span>
                  {projectData.bedroom}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">Type:</span>
                  {projectData.type}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Handover date:
                  </span>
                  {projectData.handover}
                </p>
                <p className="mb-0 text-[0.8rem] lg:text-[1rem]">
                  <span className="text-[#ffffff] font-medium pr-2">
                    Total Area:
                  </span>
                  {projectData.totalarea}
                </p>
              </div>
              <div className="border border-[#ffffff] border-spacing-1 rounded-md p-3 mt-3">
                <h6 className="text-[#ffffff] text-left text-[0.9rem] sm:text-[1.1rem] font-medium border-b-[#ffffff] border-b pb-2 mb-3">
                  Share
                </h6>
                <ul className="flex items-center justify-left gap-4 p-2 md:flex">
                  <li className="group">
                    <FaFacebookF
                      onClick={shareOnFacebook}
                      className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                    />
                  </li>
                  <li className="group">
                    <FaInstagram
                      onClick={shareOnInstagram}
                      className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                    />
                  </li>
                  <li className="group">
                    <FaLinkedin
                      onClick={shareOnLinkedIn}
                      className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                    />
                  </li>
                  <li className="group">
                    <RiWhatsappFill
                      onClick={shareOnWhatsApp}
                      className="text-[#ffffff] group-hover:text-[#CE8745] text-xl  transition duration-200 ease-out"
                    />
                  </li>
                </ul>
              </div>
              <div>
                {showPopup && (
                  <PopupModel onClose={() => setShowPopup(false)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampainPage;
